<template>
  <div >
    <el-card>
      <div class="flex-jb-al">
        <div class="order-tab">
          <span class="cur" :class="{active:activeindex=='*'}" @click="orders('*')">全部</span>
          <span class="cur" :class="{active:activeindex==0}" @click="orders(0)">待支付</span>
          <span class="cur" :class="{active:activeindex==1}" @click="orders(1)">已完成</span>
        </div>
        <!-- <el-input placeholder="请输入内容" style="width:393px;height:40px;background-color:#f5f6f8;">
          <el-button
            slot="append"
            icon="el-icon-search"
            style="background-color:#ff8547; color:#fff"
          >搜索</el-button>
        </el-input> -->
      </div>
    </el-card>
  <el-card class="mt-30" v-if="listdata.length==0">
    <div class="t-c ">暂无数据</div>
  </el-card>
    <!-- <el-card class="mt-30">
      <div class="order-title">
        <span style="margin-left:100px"></span>
        <span class="margin-l"></span>
        <span class="margin-l"></span>
        <span class="margin-l"></span>
        <span class="margin-l">全部状态</span>
        <span class="margin-l"></span>
      </div>
    </el-card> -->
    <el-card class="mt-20" v-for="item in listdata" :key="item.id">
      <div class="oder-number">
        <span class="f12 ml-30">订单号：{{item.order_no}}</span>
        <span class="f12" style="margin-left:80px">{{item.create_time}}</span>
        <!-- <i class="el-icon-delete" style="margin-left:566px"></i> -->
      </div>
      <div>
        <div class="flex oderlist">
          <div class="t-c" style="width:168px">
            <div class="bd">商品名称</div>
            <div class="mt-10">{{item.ype}} {{item.name}}</div>
          </div>
          <!-- <div class="t-c">
            <div class="bd">数量</div>
            <div class="mt-10">x</div>
          </div> -->
          <!-- <div class="t-c">
            <div class="bd">客户名称</div>
            <div class="mt-10">xxx</div>
          </div> -->
          <div class="t-c" style="width:110px">
            <div class="bd" >订单金额</div>
            <div class="mt-10">{{item.price}}元</div>
          </div>
          <div  class="t-c" style="width:110px">
            <div class="bd" >实付金额</div>
            <div v-if="item.status==1"  class="mt-10">{{item.pay_price}}元</div>
            <div v-else class="mt-10">未支付</div>
          </div>
          <div class="t-c">
            <div class="bd">状态</div>
            <div class="mt-10" @click="gofee(item)" :class="item.status==1?'b-r':item.status==0?'b-y':'b-h'">{{item.status==1?'支付成功':item.status==0?'去支付':'订单失效'}}</div>
          </div>
          <!-- <div class="t-c">
            <div class="bd">操作</div>
            <div class="mt-10" :class="item.status==0?'invoice':item.is_invoice==0?'pay':''">{{item.status==0?'去付款':item.is_invoice==0?'申请发票':''}}</div>
          </div> -->
        </div>
      </div>
    </el-card>
    <div class="t-c mt-30 p1" v-show="listdata.length!=0" >
       <el-pagination
          @current-change="changePage"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pagesize"
          :current-page.sync='currentpage'
        ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeindex:'*',
      listdata: [],
       pagesize: 3,
      page: 1,
      total: 0,
      currentpage:1

    };
  },
  created() {
    this.getorders('*');
  },
  methods: {
    orders(index){
       this.activeindex=index
       this.currentpage=1
       this.page=1
       this.getorders(index)
    },
    getorders(index) {
      this.$request.get(`/client/orders/listData?status=${index}&page=${this.page}&limit=${this.pagesize}`).then(res => {
        if (res.data.code == 200) {
          this.listdata = res.data.data;
          this.total=res.data.total
        }
      });
    },
     changePage(index) {
      this.page = index;
      this.getorders(this.activeindex)
    },
    gofee(item){
      if(item.status==0){
        this.$router.push({path:'/recharge',query:{type:'no_num', order_no:item.order_no,price:item.price - item.coupon_price<=0?"0.00":item.price - item.coupon_price,typeId:item.type}})
      }
    }
  }
};
</script>
<style scoped>
.el-card >>> .el-input-group__append,
.el-input-group__prepend {
  background-color: #ff8547;
  border-color: #ff8547;
}
.order-tab span {
  display: inline-block;
  width: 120px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  color: #979797;
  font-size: 18px;
}
.order-tab span.active {
  color: #2962ff;
}
.order-title span {
  color: #222530;
  font-size: 14px;
  font-weight: 700;
}
.margin-l {
  margin-left: 122px;
}
.oder-number {
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.oderlist {
  height: 88px;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  padding: 0 124px 0 30px;
}
.invoice,
.pay {
  width: 96px;
  height: 28px;
  border-radius: 4px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.pay{
  background: #2962ff;
}
.invoice {
  background-color: #ff8547;
}
 .p1 >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2962ff;
  color: #fff;
}
.b-r{
  color:#52C41A;
  border: 1px solid #52C41A;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: #f6ffed;
}
.b-y{
  color:#FAAD14 ;
   border: 1px solid #FAAD14;
    padding: 2px 11px;
  border-radius: 4px;
  background-color: #fffbe6;
  cursor: pointer;
}
.b-h{
 border: 1px solid;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: #eee;
}
</style>
